<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import useNetw from "@useNetw";
import Swal from "sweetalert2";
import btnComponent from "@/components/btnComponent";
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
// import branchComponent from "@/components/branchComponent.vue";
// import { tableData } from "./dataAdvancedtable";

/**
 * Advanced-table component
 */
export default {
  page: {
    title: "สั่งซื้อรถเข้าสต็อก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, btnComponent, Multiselect, DatePicker },
  data() {
    return {
      // tableData: tableData,

      title: "สั่งซื้อรถเข้าสต็อก",
      items: [
        {
          text: "คลังสินค้า",
          active: true,
        },
        {
          text: "สั่งซื้อรถเข้าสต็อก",
          active: false,
        },
      ],
      rowsSelect: [],
      chk: "",
      selected: [],
      rowsTotalpages: "",
      rowsperPage: "",
      loading: false,
      urlPrint: "",
      rowsPage: "",
      poId: "",
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      arrTest: [],
      totalRecords: 0,
      pageOptions: [10, 25, 50, 100],
      optionBranch: [],
      optionStatus: [
        {
          name: "ยืนยันแล้ว",
          status: "APV",
        },
        {
          name: "ยกเลิกแล้ว",
          status: "CAN",
        },
        {
          name: "ร่าง",
          status: "DFT",
        },
      ],
      filter: {
        supId: "",
        poDate: "",
        poCode: "",
        status: "",
        userBranchId: "",
      },
      userBranch: "",
      filterOn: [],
      rowsPO: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          sortable: false,
        },
        {
          key: "poCode",
          sortable: true,
          label: "รหัสสั่งซื้อ",
        },
        {
          key: "supplierNameTh",
          sortable: true,
          label: "ผู้ผลิต",
        },
        {
          key: "poDate",
          sortable: true,
          label: "วันที่สั่งซื้อ",
        },
        {
          key: "grandTotalPrice",
          sortable: true,
          label: "ราคารวม",
          class: "text-end",
        },
        {
          key: "branchName",
          sortable: true,
          label: "สาขา",
        },
        {
          key: "status",
          label: "สถานะ",
          class: "text-center",
        },
        {
          key: "action",
          label: appConfig.edit,
          sortable: false,
        },
      ],
      overlayFlag: false,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowsPO.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    const arrayBranchAll = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
      arrayBranchAll.push(item);
    });
    this.userBranch = arrayBranch;
    this.optionBranch = arrayBranchAll;
    this.totalRows = this.rowsPO.total;
  },
  created() {
    this.getData();
    // this.select()
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      // this.currentPage = 1;
    },
    getData: function() {
      this.overlayFlag = true;
      useNetw
        .get("api/vehicle-po", {
          params: {
            page: this.currentPage,
            perPage: this.perPage,
            "branchId[]":
              this.filter.userBranchId != null
                ? this.filter.userBranchId.branchId
                : undefined,
            supId: this.filter.supId,
            poDate: this.filter.poDate,
            poCode: this.filter.poCode,
            status:
              this.filter.status != null
                ? this.filter.status.status
                : undefined,
          },
        })
        .then((response) => {
          this.rowsPO = response.data.data;
          this.rowsPO.to = response.data.to;
          this.rowsPO.from = response.data.from;
          this.rowsPO.total = response.data.total;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(appConfig.swal.title.Unauthorization),
              // appConfig.swal.title.Unauthorization,
              appConfig.swal.type.error
              // appConfig.swal.title.Unauthorization
            );
            // this.$router.push("/login");
          }
        })
        .then(() => {
          this.overlayFlag = false;
        });
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.getData();
    },
    handlePageChange(active) {
      this.perPage = active;
      this.currentPage = 1;
      this.getData();
    },
    onRowSelected(rowsPO) {
      this.selected = rowsPO;
    },
    getVehiclePoPrint(poId) {
      this.poId = poId;
      this.loading = true;
      useNetw
        .get("api/vehicle-po/print", {
          params: {
            poId: this.poId,
          },
        })
        .then((response) => {
          this.urlPrint = response.data.url;
          // window.open(this.urlPrint);
          window.location = (this.urlPrint);
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          this.loading = false;
        });
    },
    handleSearch() {
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="overlayFlag"
          :spinner-variant="this.$overlayVariant"
          :spinner-type="this.$overlayType"
          :rounded="this.$overlayRounded"
        >
          <div class="card">
            <div class="card-body">
              <!-- <h4 class="card-title">Vehicle List</h4> -->
              <div class="row">
                <div class="col-sm-12 col-md-2"></div>
              </div>
              <!-- Search -->
              <div class="row justify-content-md-center">
                <div class="col-sm-12 col-md-12">
                  <div class="row">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="row">
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            สาขา:
                            <multiselect
                              v-model="filter.userBranchId"
                              label="nameTh"
                              type="search"
                              :options="optionBranch"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            รหัสผู้ผลิต:
                            <b-form-input
                              v-model="filter.supId"
                              type="search"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            รหัสสั่งซื้อ:
                            <b-form-input
                              v-model="filter.poCode"
                              type="search"
                              class="form-control"
                              @keyup.enter="handleSearch"
                            ></b-form-input>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            วันที่สั่งซื้อ:
                            <date-picker
                              v-model="filter.poDate"
                              format="YYYY-MM-DD"
                              value-type="format"
                            ></date-picker>
                          </div>
                        </div>
                        <div class="col-6 col-sm-6 col-md-4">
                          <div class="mb-3 position-relative">
                            สถานะ:
                            <multiselect
                              v-model="filter.status"
                              label="name"
                              type="search"
                              :options="optionStatus"
                              :show-labels="false"
                              open-direction="bottom"
                              placeholder=""
                            >
                              <span slot="noResult">ไม่พบข้อมูล</span>
                            </multiselect>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-md-12 text-end">
                            &nbsp;
                            <b-button
                              class="btn m-2"
                              variant="primary"
                              type="submit"
                              :to="{ name: 'create-vehicle-po' }"
                            >
                              <i class="uil-plus"></i>
                              สร้าง
                            </b-button>
                            <btnComponent
                              @click="handleSearch"
                              changeStyle="search"
                            >
                            </btnComponent>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          แสดงผล&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                            @input="handlePageChange"
                          ></b-form-select
                          >&nbsp;รายการ
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <!-- Table -->
              <b-skeleton-wrapper :loading="loading">
                <!-- skeleton  -->
                <template #loading>
                  <b-skeleton-table></b-skeleton-table>
                </template>
              <div class="table-responsive mb-0">
                <b-table
                  :items="rowsPO"
                  :fields="fields"
                  :current-page="1"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                  responsive="sm"
                  ref="selectableTable"
                  selectable
                  @row-selected="onRowSelected"
                >
                  <template #cell(index)="rowsPO">
                    {{ rowsPO.index + 1 }}
                  </template>
                  <template v-slot:cell(action)="rowsPO">
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <router-link
                          href="javascript:void(0);"
                          class="px-2 text-primary"
                          v-b-tooltip.hover
                          :to="{
                            name: 'detail-vehicle-po',
                            params: { poId: rowsPO.item.poIdBase64 },
                          }"
                          title="Edit"
                        >
                          <i class="uil uil-pen font-size-18"></i>
                        </router-link>
                      </li>
                      <li>
                          <a
                            href="javascript:void(0);"
                            class="px-2 text-success"
                            v-b-tooltip.hover
                            title="Print"
                            @click="getVehiclePoPrint(rowsPO.item.poId)"
                          >
                            <i class="uil-print font-size-18"></i>
                          </a>
                        </li>
                      <!-- <li class="list-inline-item">
                        <a
                          href="javascript:void(0);"
                          class="px-2 text-danger"
                          v-b-tooltip.hover
                          title="Delete"
                          @click="alertDelete(rowsPO.item.poId)"
                        >
                          <i class="uil uil-trash-alt font-size-18"></i>
                        </a>
                      </li> -->
                    </ul>
                  </template>
                  <template #cell(status)="rowsPO">
                    <span
                      v-if="rowsPO.item.status === 'APV'"
                      class="badge bg-success"
                      >ยืนยันแล้ว</span
                    >
                    <span
                      v-if="rowsPO.item.status === 'CAN'"
                      class="badge bg-danger"
                      >ยกเลิกแล้ว</span
                    >
                    <span
                      v-if="rowsPO.item.status === 'DFT'"
                      class="badge bg-warning"
                      >ร่าง</span
                    >
                  </template>
                </b-table>
                
                <!-- <p class="float-end">
                  <b-button class="m-2" size="sm" @click="selectAllRows"
                    >Select all</b-button
                  >
                  <b-button class="m-2" size="sm" @click="clearSelected"
                    >Clear selected</b-button
                  >
                </p> -->
              </div>
              </b-skeleton-wrapper>
              <div class="row">
                <div class="col">
                  <div class="align-items-center">
                    แสดงผล {{ perPage }} หน้า {{ currentPage }} จาก
                    {{ this.rowsPO.total }} รายการ
                  </div>
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rowsPO.total"
                        :per-page="perPage"
                        @change="handleChangePage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
